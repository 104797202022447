import { render, staticRenderFns } from "./ModalTransmissaoCampanha.vue?vue&type=template&id=303b5f87&scoped=true&"
import script from "./ModalTransmissaoCampanha.vue?vue&type=script&lang=js&"
export * from "./ModalTransmissaoCampanha.vue?vue&type=script&lang=js&"
import style0 from "./ModalTransmissaoCampanha.vue?vue&type=style&index=0&id=303b5f87&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303b5f87",
  null
  
)

export default component.exports